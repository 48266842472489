import React from 'react';
import { NSContext, NSLogin } from 'aqlrc';
import CMS from './CMS';
import { withI18next } from '../lib/withI18n';
import { listModulePage } from '../lib/utils';

/**
 * Login - Formulaire de connexion / inscription (surcharge NSLogin)
 * @return {React.Component}
 */

class Login extends NSLogin {
    render() {
        const { t } = this.props;
        const {
            emailResetPassword, step, user, address, countries, subUser, subscribeNL
        } = this.state;
        return (
            <div className="steps__body">
                <div className="step">
                    <div className="step__container">
                        <div className="cols">
                            <div className="col">
                                <div className="box-accordion box-accordion--orange active">
                                    <header className="box__head">
                                        <h3 className="box__title">
                                                NOUVEAU CLIENT ?

                                            <small>Inscrivez-vous avec :</small>
                                        </h3>{/* <!-- /.box__title --> */}
                                    </header>{/* <!-- /.box__head --> */}

                                    <div className="box__body">
                                        <div className="form form--light">
                                            <form onSubmit={this.handleRegisterSubmit} method="post">
                                                <div className="form__row">
                                                    <div className="form__controls" style={{ display: 'flex' }}>
                                                        <div className="checkbox checkbox--radio">
                                                            <input type="radio" name="civility" id="field_civility_women" value="1" onChange={(e) => this.handleChangeSub(e)} />
                                                            <label htmlFor="field_civility_women" className="form__label">Madame</label>
                                                        </div>
                                                        <div className="checkbox checkbox--radio" style={{ marginLeft: '20px' }}>
                                                            <input type="radio" name="civility" id="field_civility_men" value="0" onChange={(e) => this.handleChangeSub(e)} />
                                                            <label htmlFor="field_civility_men" className="form__label">Monsieur</label>
                                                        </div>
                                                    </div>{/* <!-- /.form__controls --> */}
                                                </div>{/* <!-- /.form__row --> */}

                                                <div className="form__row">
                                                    <label htmlFor="field_lastname" className="form__label">Nom<span>*</span></label>

                                                    <div className="form__controls">
                                                        <input type="text" className="field" name="lastname" id="field_lastname" value={subUser.lastname} onChange={(e) => this.handleChangeSub(e)} required />
                                                    </div>{/* <!-- /.form__controls --> */}
                                                </div>{/* <!-- /.form__row --> */}

                                                <div className="form__row">
                                                    <label htmlFor="field_firstname" className="form__label">Prénom<span>*</span></label>

                                                    <div className="form__controls">
                                                        <input type="text" className="field" name="firstname" id="field_firstname" value={subUser.firstname} onChange={(e) => this.handleChangeSub(e)} required />
                                                    </div>{/* <!-- /.form__controls --> */}
                                                </div>{/* <!-- /.form__row --> */}

                                                <div className="form__row">
                                                    <label htmlFor="field_zipcode" className="form__label">Société</label>

                                                    <div className="form__controls">
                                                        <input type="text" className="field" name="address/companyName" id="field_companyName" value={address.companyName} onChange={(e) => this.handleChangeSub(e)} />
                                                    </div>{/* <!-- /.form__controls --> */}
                                                </div>{/* <!-- /.form__row --> */}

                                                <div className="form__row">
                                                    <label htmlFor="field_address" className="form__label">Adresse<span>*</span></label>

                                                    <div className="form__controls">
                                                        <input type="text" className="field" name="address/line1" id="field_address" value={address.line1} onChange={(e) => this.handleChangeSub(e)} required />
                                                    </div>{/* <!-- /.form__controls --> */}
                                                </div>{/* <!-- /.form__row --> */}

                                                <div className="form__row">
                                                    <label htmlFor="field_address2" className="form__label">Adresse (complément)</label>

                                                    <div className="form__controls">
                                                        <input type="text" className="field" name="address/line2" id="field_address2" value={address.line2} onChange={(e) => this.handleChangeSub(e)} />
                                                    </div>{/* <!-- /.form__controls --> */}
                                                </div>{/* <!-- /.form__row --> */}

                                                <div className="form__row">
                                                    <label htmlFor="field_city" className="form__label">Ville<span>*</span></label>

                                                    <div className="form__controls">
                                                        <input type="text" className="field" name="address/city" id="field_city" value={address.city} onChange={(e) => this.handleChangeSub(e)} required />
                                                    </div>{/* <!-- /.form__controls --> */}
                                                </div>{/* <!-- /.form__row --> */}

                                                <div className="form__row">
                                                    <label htmlFor="field_zipcode" className="form__label">Code postal<span>*</span></label>

                                                    <div className="form__controls">
                                                        <input type="text" className="field" name="address/zipcode" id="field_zipcode" value={address.zipcode} onChange={(e) => this.handleChangeSub(e)} required />
                                                    </div>{/* <!-- /.form__controls --> */}
                                                </div>{/* <!-- /.form__row --> */}

                                                <div className="form__row">
                                                    <label htmlFor="field_country" className="form__label">Pays<span>*</span></label>

                                                    <div className="form__controls">
                                                        <div className="select">
                                                            <div className="select__controls">
                                                                <select defaultValue="" className="field" name="address/isoCountryCode" id="field_country" value={address.isoCountryCode} onChange={(e) => this.handleChangeSub(e)} required>
                                                                    <option value="">--</option>
                                                                    {
                                                                        countries.map((c) => (<option key={c._id} value={c.code}>{c.name}</option>))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form__row">
                                                    <label htmlFor="field_phone" className="form__label">Téléphone<span>*</span></label>

                                                    <div className="form__controls">
                                                        <input type="text" className="field" name="address/phone" id="field_phone" value={address.phone} onChange={(e) => this.handleChangeSub(e)} required />
                                                    </div>{/* <!-- /.form__controls --> */}
                                                </div>{/* <!-- /.form__row --> */}

                                                <div className="form__row">
                                                    <label htmlFor="field_password" className="form__label">Mot de passe<span>*</span></label>

                                                    <div className="form__controls">
                                                        <input type="password" className="field" name="password" id="field_password" value={subUser.password} onChange={(e) => this.handleChangeSub(e)} required />
                                                    </div>{/* <!-- /.form__controls --> */}
                                                </div>{/* <!-- /.form__row --> */}

                                                <div className="form__row">
                                                    <label htmlFor="field_passwordConfirm" className="form__label">Mot de passe (confirmation)<span>*</span></label>

                                                    <div className="form__controls">
                                                        <input type="password" className="field" name="passwordConfirm" id="field_passwordConfirm" value={subUser.passwordConfirm} onChange={(e) => this.handleChangeSub(e)} required />
                                                    </div>{/* <!-- /.form__controls --> */}
                                                </div>{/* <!-- /.form__row --> */}

                                                <div className="form__row">
                                                    <label htmlFor="field_email" className="form__label">Email<span>*</span></label>

                                                    <div className="form__controls">
                                                        <input type="email" className="field" name="email" id="field_email" value={subUser.email} onChange={(e) => this.handleChangeSub(e)} required />
                                                    </div>{/* <!-- /.form__controls --> */}
                                                </div>{/* <!-- /.form__row --> */}

                                                <div className="form__row">
                                                    <label htmlFor="field_confirmEmail" className="form__label">Email (confirmation)<span>*</span></label>

                                                    <div className="form__controls">
                                                        <input type="email" className="field" name="confirmEmail" id="field_confirmEmail" value={subUser.confirmEmail} onChange={(e) => this.handleChangeSub(e)} required />
                                                    </div>{/* <!-- /.form__controls --> */}
                                                </div>{/* <!-- /.form__row --> */}

                                                <div className="form__row">
                                                    <div className="form__controls" style={{ display: 'flex' }}>
                                                        <div className="checkbox">
                                                            <input type="checkbox" name="newsletter" id="field_newsletter" checked={subscribeNL} value={subscribeNL} onChange={() => this.setState({ subscribeNL: !subscribeNL })} />
                                                            <label htmlFor="field_newsletter" className="form__label">Recevez toutes les promotions et actus en cours</label>
                                                        </div>
                                                    </div>{/* <!-- /.form__controls --> */}
                                                </div>{/* <!-- /.form__row --> */}

                                                <div className="form__actions">
                                                    <button type="submit" className="btn btn--white">CREER VOTRE COMPTE</button>
                                                </div>{/* <!-- /.form__actions --> */}
                                            </form>
                                        </div>{/* <!-- /.form --> */}
                                    </div>{/* <!-- /.box__body --> */}
                                </div>{/* <!-- /.box-accordion --> */}
                            </div>{/* <!-- /.col --> */}

                            <div className="col">
                                <div className="box-accordion">
                                    <header className="box__head">
                                        <h3 className="box__title">
                                                { step === 1 ? 'MOT DE PASSE OUBLIÉ ?' : 'DEJA CLIENT ?'}

                                            <small>{step === 1 ? 'Réinitialisez votre mot de passe' : 'Connectez-vous avec'} :</small>
                                        </h3>{/* <!-- /.box__title --> */}
                                    </header>{/* <!-- /.box__head --> */}

                                    <div className="box__body">
                                        <div className="form form--light">
                                            {
                                                step === 1 ? (
                                                    <form onSubmit={(e) => this.handleResetSubmit(e)} method="post">
                                                        <div className="form__row">
                                                            <label htmlFor="email_login" className="form__label">Email</label>

                                                            <div className="form__controls">
                                                                <input type="email" className="field" name="field_email" id="email_login" value={emailResetPassword} onChange={(e) => this.handleResetPassword(e)} />
                                                            </div>{/* <!-- /.form__controls --> */}
                                                            <span className="form__controls-meta" style={{ cursor: 'pointer' }} onClick={() => this.setState({ step: 0 })}>Retour</span>
                                                        </div>{/* <!-- /.form__row --> */}

                                                        <div className="form__actions">
                                                            <button type="submit" className="btn btn--white">VALIDER</button>
                                                        </div>{/* <!-- /.form__actions --> */}
                                                    </form>
                                                ) : (
                                                    <form onSubmit={this.handleLoginSubmit} method="post">
                                                        <div className="form__row">
                                                            <label htmlFor="email_login" className="form__label">Email</label>

                                                            <div className="form__controls">
                                                                <input type="email" className="field" name="field_email_old_client" id="email_login" value={user.email} />
                                                            </div>{/* <!-- /.form__controls --> */}
                                                        </div>{/* <!-- /.form__row --> */}

                                                        <div className="form__row">
                                                            <label htmlFor="password_login" className="form__label">Mot de passe</label>

                                                            <div className="form__controls">
                                                                <input type="password" className="field" name="field_password" id="password_login" value={user.password} />

                                                                <span className="form__controls-meta" style={{ cursor: 'pointer' }} onClick={() => this.setState({ step: 1 })}>Mot de passe oublié ?</span>
                                                            </div>{/* <!-- /.form__controls --> */}
                                                        </div>{/* <!-- /.form__row --> */}

                                                        <div className="form__row">
                                                        {
                                                            listModulePage('auth')
                                                        }
                                                        </div>{/* <!-- /.form__row --> */ }

                                                        <div className="form__actions">
                                                            <button type="submit" className="btn btn--white">IDENTIFIEZ-VOUS</button>
                                                        </div>{/* <!-- /.form__actions --> */}
                                                    </form>
                                                )
                                            }
                                        </div>{/* <!-- /.form --> */}
                                    </div>{/* <!-- /.box__body --> */}
                                </div>{/* <!-- /.box-accordion --> */}
                            </div>{/* <!-- /.col --> */}
                        </div>{/* <!-- /.cols --> */}
                    </div>{/* <!-- /.step__container --> */}
                </div>{/* <!-- /.step --> */}
            </div>/* <!-- /.steps__body --> */
        );
    }
}

export default withI18next(['login'])(Login);
